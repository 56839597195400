import React, { Fragment, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Cover from '../../components/Cover';
import './Products.css';
import Footer from '../../components/Footer';
import product_cover from '../../assets/images/products/products_cover.png';
import textile from '../../assets/images/products/textile.png';
import furniture from '../../assets/images/products/furniture.png';
import home_imp from '../../assets/images/products/home_imp.png';
import home_decor from '../../assets/images/products/home_decor.png';
import leaf from '../../assets/images/products/leaf.png';
import leaf2 from '../../assets/images/products/leaf2.png';
import leaf3 from '../../assets/images/products/leaf3.png';
import leaf4 from '../../assets/images/products/leaf4.png';
import textile_img from '../../assets/images/products/textile/textile_main.png';
import furniture_img from '../../assets/images/products/furniture2/furniture_main.png';
import decor_img from '../../assets/images/products/home_decor2/decor_main.png';
import improve_img from '../../assets/images/products/home_improve2/imp_main.png';
import ProductCarousel from '../../components/ProductCarousel';
import { product_img, product_img2, product_img3, product_img4 } from '../../components/data';

const Products = () => {
	const scrollToTextile = () => {
		const kitchenSection = document.getElementById('textile_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToFurniture = () => {
		const kitchenSection = document.getElementById('furniture_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToImprovement = () => {
		const kitchenSection = document.getElementById('improvement_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToDecor = () => {
		const kitchenSection = document.getElementById('decor_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const scrollTo = urlParams.get('scrollTo');

		if (scrollTo === 'textile_sec') {
			const companySection = document.getElementById('textile_sec');
			if (companySection) {
				window.scrollTo({
					top: companySection.offsetTop,
					behavior: 'smooth'
				});
			}
		}
		if (scrollTo === 'furniture_sec') {
			const companySection = document.getElementById('furniture_sec');
			if (companySection) {
				window.scrollTo({
					top: companySection.offsetTop,
					behavior: 'smooth'
				});
			}
		}
		if (scrollTo === 'improvement_sec') {
			const companySection = document.getElementById('improvement_sec');
			if (companySection) {
				window.scrollTo({
					top: companySection.offsetTop,
					behavior: 'smooth'
				});
			}
		}
		if (scrollTo === 'decor_sec') {
			const companySection = document.getElementById('decor_sec');
			if (companySection) {
				window.scrollTo({
					top: companySection.offsetTop,
					behavior: 'smooth'
				});
			}
		}
	}, []);

	return (
		<Fragment>
			<Navbar />
			<Cover coverImageSrc={product_cover} title="Products" subTitle="We want you to know all about us!" />
			<section className="product_sec first_sec">
				<div className="product_div">
					<div className="product_card card1" onClick={scrollToTextile}>
						<img src={textile} alt="" />
						<div className="heading-cover">
							<h1 className="textile_head">Textiles and Rugs</h1>
						</div>
					</div>
					<img className="leaf" src={leaf} alt="" />
					<div className="product_card card2" onClick={scrollToFurniture}>
						<img src={furniture} alt="" />
						<div className="heading-cover">
							<h1 className="furniture_head">Furniture</h1>
						</div>
					</div>
				</div>
				<div className="product_tagline">
					<img className="leaf2" src={leaf2} alt="" />
					<h2>Your one-stop sourcing solution for all home fashion & utility needs</h2>
					<img className="leaf2" src={leaf3} alt="" />
				</div>
				<div className="product_div">
					<div className="product_card card3" onClick={scrollToImprovement}>
						<img src={home_imp} alt="" />
						<div className="heading-cover">
							<h1 className="home_imp_head">Home Improvement</h1>
						</div>
					</div>
					<img className="leaf3" src={leaf4} alt="" />
					<div className="product_card card4" onClick={scrollToDecor}>
						<img src={home_decor} alt="" />
						<div className="heading-cover">
							<h1 className="home_decor_head">Home Decor & Utility</h1>
						</div>
					</div>
				</div>
			</section>
			<section className="product_sec sec_back" id="textile_sec">
				<div className="prod_container main-img-1">
					<img src={textile_img} alt="" />
					<div className="prod_content">
						<h1>Textiles & Rugs</h1>
						<p>
							Explore a <b>diverse range</b> of <b>fabrics</b> and <b>home textiles</b> sourced from top
							manufacturers in India<br />
							<br />
							From luxurious silks to durable cottons, we curate quality textiles to{' '}
							<b>meet your import needs</b>
							<br />
							<br />
							Discover an array of patterns, colours, and textures for your business success.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img} />
			</section>
			<section className="product_sec sec_back" id="furniture_sec">
				<div className="prod_container prod_reverse main-img-2">
					<img src={furniture_img} alt="" />
					<div className="prod_content content_reverse">
						<h1>Furniture</h1>
						<p className="p_furniture">
							Browse through a curated selection of exquisite furniture pieces, from{' '}
							<b>modern to classic designs</b>
							<br />
							<br />
							Our sourcing capabilities include <b>durable and stylish furniture</b> crafted with
							precision
							<br />
							<br />
							A blend of <b>aesthetic appeal and functionality</b>. Find the perfect pieces to elevate
							your market presence
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img2} />
			</section>
			<section className="product_sec sec_back" id="decor_sec">
				<div className="prod_container main-img-1">
					<img src={decor_img} alt="" />
					<div className="prod_content">
						<h1>Home Decor & Utility</h1>
						<p>
							Discover an exclusive collection of{' '}
							<b>home decoration, accessories, kitchenware, tableware, cookware</b>, and <b>crockery</b>.
							<br />
							<br />
							<b>Functional</b> and <b>decorative products</b> to help your target customers elevate their
							living spaces and dining experiences - manufactured with care in India
							<br />
							<br />
							Tailored to meet your business’ functional, aesthetic and price-sensitive desires.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img3} />
			</section>
			<section className="product_sec sec_back" id="improvement_sec">
				<div className="prod_container prod_reverse main-img-2">
					<img src={improve_img} alt="" />
					<div className="prod_content content_reverse">
						<h1>Home Improvement</h1>
						<p className="p_furniture">
							Discover a vast array of high-quality{' '}
							<b>floor tiles, durable hardware, sleek sanitary ware</b>, and <b>elegant bath fittings</b>
							<br />
							<br />
							Tailored to your business needs, succeed with products that seamlessly blend{' '}
							<b>functionality, style</b>, and <b>durability</b>
							<br />
							<br />
							Choose from a curated range of designs to suit your aesthetic preferences.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img4} />
			</section>
			<Footer />
		</Fragment>
	);
};

export default Products;
