import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import contact_cover from '../../assets/images/contact/contact_cover.png';
import Cover from '../../components/Cover';
import merchandise from '../../assets/images/contact/merchandise.png';
import quality from '../../assets/images/contact/quality.png';
import sourcing from '../../assets/images/contact/sourcing.png';
import logistics from '../../assets/images/contact/logistics.png';
import apply from '../../assets/images/contact/apply.png';
import './Contact.css';
import Footer from '../../components/Footer';

const Contact = () => {
	// const [ name, setName ] = useState('');
	// const [ email, setEmail ] = useState('');
	// const [ phoneNo, setPhoneNo ] = useState('');
	// const [ message, setMessage ] = useState('');

	// submit event
	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	const data = {
	// 		name,
	// 		email,
	// 		phoneNo,
	// 		message
	// 	};
	// 	console.log(data);
	// };
	const scrollToCareers = () => {
		const kitchenSection = document.getElementById('career_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<Fragment>
			<Navbar />
			<Cover coverImageSrc={contact_cover} title="Let's Connect" subTitle="We want you to know all about us..." />
			<section className="contact_sec">
				<div class="contact-whole">
					<div className="talk-whole">
						<div className="lets-talk">
							<h1>LET'S TALK</h1>
						</div>
						<div className="vendor-form">
							<a href="https://forms.office.com/r/twUREd0hk9" target="_blank" rel="noreferrer">
								Fill this form out!
							</a>
						</div>
						{/* <form autoComplete="off" onSubmit={handleSubmit}>
							<div class="c-form-group">
								<div class="c-form-field">
									<input
										type="text"
										placeholder="Name: *"
										className="form-control"
										required
										onChange={(e) => setName(e.target.value)}
										value={name}
									/>
								</div>
								<div class="c-form-field">
									<input
										type="email"
										className="form-control"
										placeholder="Email ID *"
										required
										onChange={(e) => setEmail(e.target.value)}
										value={email}
									/>
								</div>
								<div class="c-form-field">
									<input
										type="text"
										placeholder="Phone Number *"
										className="form-control"
										required
										onChange={(e) => setPhoneNo(e.target.value)}
										value={phoneNo}
									/>
								</div>
								<div class="c-form-field">
									<textarea
										className="form-control comments"
										placeholder="Leave a Message:"
										onChange={(e) => setMessage(e.target.value)}
										value={message}
									/>
								</div>
							</div>
							<div className="c-submit-cont">
								<button type="submit" className="btn btn-primary">
									Submit
								</button>
							</div>
						</form> */}
					</div>
					<div class="contact-touch-whole">
						<div className="contact-touch">
							<h1>GET IN TOUCH</h1>
							<div className="contact-touch-content">
								<table>
									<tr>
										<th>
											<p>Address:</p>
										</th>
										<td>
											<p>
												<a
													href="https://www.google.com/maps?q=127,+Arihant+Nagar,+Punjabi+Bagh+(West),+New+Delhi+-+110026,+India"
													target="_blank"
													rel="noreferrer"
												>
													127, Arihant Nagar, Punjabi Bagh West, New Delhi - 110026, India
												</a>
											</p>
										</td>
									</tr>
									<tr>
										<th>
											<p>Phone:</p>
										</th>
										<td>
											{/* <p>+91-11-47 02 78 79</p> */}
											<p>
												<a href="tel:+91-11-47 02 78 79">+91-11-47 02 78 79</a>
											</p>
										</td>
									</tr>
									<tr>
										<th>
											<p>Email:</p>
										</th>
										<td>
											<p>
												<a href="mailto:info@sapphiresourcings.com">
													info@sapphiresourcings.com
												</a>
											</p>
										</td>
									</tr>
								</table>
							</div>
						</div>
						{/* <div className="contact-touch-img">
							<img src={touch_img} alt="" />
						</div> */}
					</div>
				</div>
			</section>
			<section className="contact_sec">
				<div className="work-whole">
					<h2>Work with us</h2>
					<p>
						At Sapphire Sourcings, we work across a wide variety of products and nurture a culture of
						intra-preneurship and cross-departmental functioning for our teammates.
					</p>
					<span onClick={scrollToCareers}>EXPLORE CAREERS</span>
				</div>
			</section>
			<section className="contact_sec" id="career_sec">
				<div className="careers-whole">
					<div className="career-card-whole">
						<div className="image-heading">
							<img src={merchandise} alt="" />
							<h2>MERCHANDISING</h2>
						</div>
						<div className="career-card-content">
							<div className="join">
								<p>
									<b>Join our Merchandising Team</b>
								</p>
								<p>
									At Sapphire, we believe in fostering a dynamic work environment that thrives on
									collaboration, flexibility, and continuous learning. Our merchandising department
									plays a pivotal role in our success, and we're on the lookout for passionate
									individuals who are ready to embark on an exciting journey in the world of buying
									and merchandising
								</p>
							</div>
							<div className="why-choose">
								<p>
									<b>Why Choose a Merchandising Career with Us?</b>
								</p>
								<div className="why-content">
									<p>Cross-departmental Functioning</p>
									<p>International Exposure</p>
									<p>Continuous Learning and Growth</p>
									<p>Collaborative Environment</p>
								</div>
							</div>
							<p className="journey">
								Your journey with us will be filled with challenges, growth, and a supportive work
								environment that encourages you to be your best.
							</p>
						</div>
					</div>
					<div className="career-card-whole">
						<div className="image-heading">
							<img src={quality} alt="" />
							<h2>QUALITY</h2>
						</div>
						<div className="career-card-content">
							<div className="join">
								<p>
									<b>Join our Quality Control & Assurance Team</b>
								</p>
								<p>
									Are you passionate about quality, precision, and ensuring the satisfaction of
									customers worldwide? At Sapphire, we take immense pride in our commitment to
									delivering top-quality products and services. Our Quality Control and Assurance team
									is at the heart of this mission, and we're looking for dedicated individuals to join
									us in this endeavor.
								</p>
							</div>
							<div className="why join">
								<p>
									<b>Why Choose a QA/QC Career with Us?</b>
								</p>
								<div className="why-content">
									<p>Dynamic Work Environment</p>
									<p>Training and Development</p>
									<p>Impactful Work</p>
									<p>Global Visibility</p>
								</div>
							</div>
							<p className="journey">
								Your journey with us will be filled with challenges, growth, and a supportive work
								environment that encourages you to be your best.
							</p>
						</div>
					</div>
					<div className="career-card-whole">
						<div className="image-heading">
							<img src={sourcing} alt="" />
							<h2>SOURCING</h2>
						</div>
						<div className="career-card-content">
							<div className="join">
								<p>
									<b>Join our Sourcing Team </b>
								</p>
								<p>
									Are you passionate about sourcing top-quality products from across the country and
									ensuring that they meet and exceed the expectations of our clients? If so, our
									Product Sourcing team may be the perfect fit for you. At Sapphire, we take great
									pride in our commitment to quality, innovation, and global diversity.
								</p>
							</div>
							<div className="why join">
								<p>
									<b>Why Choose a Sourcing Career with Us?</b>
								</p>
								<div className="why-content">
									<p>Sustainability Focus</p>
									<p>Diverse Product Capabilities</p>
									<p>Intra-organizational Learnings</p>
									<p>Multi-faceted Cultural Exposure</p>
								</div>
							</div>
							<p className="journey">
								Your journey with us will be filled with challenges, growth, and a supportive work
								environment that encourages you to be your best.
							</p>
						</div>
					</div>
					<div className="career-card-whole">
						<div className="image-heading">
							<img src={logistics} alt="" />
							<h2>LOGISTICS & DOCUMENTATION</h2>
						</div>
						<div className="career-card-content">
							<div className="join">
								<p>
									<b>Join our Logistics & Documentation Team </b>
								</p>
								<p>
									At Sapphire, we understand that the backbone of any successful buying house
									operation is a robust logistics and documentation team. If you're looking to join a
									dynamic team and make a significant impact in a fast-paced environment, you've come
									to the right place.
								</p>
							</div>
							<div className="why join">
								<p>
									<b>Why Choose a Logistics & Documentation Career with Us?</b>
								</p>
								<div className="why-content">
									<p>Key to Efficient Operations</p>
									<p>Continuous Learning and Growth</p>
									<p>Exposure to International Operations</p>
									<p>Collaborative Work Environment</p>
								</div>
							</div>
							<p className="journey">
								Your journey with us will be filled with challenges, growth, and a supportive work
								environment that encourages you to be your best.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="contact_sec">
				<a href="https://forms.office.com/r/L6aDj4jfia" target="_blank" rel="noreferrer" className="apply-now">
					<div className="apply-img">
						<img src={apply} alt="" />
					</div>

					<a href="https://forms.office.com/r/L6aDj4jfia" target="_blank" rel="noreferrer">
						APPLY NOW
					</a>
					<p>Click here to fill the form out</p>
				</a>
			</section>
			<section className="contact_sec">
				<div class="explore-opp">
					<div class="opp">
						<h2>Gain better understanding of our roles</h2>
						<a
							href="https://www.linkedin.com/company/sapphire-sourcings/jobs/"
							target="_blank"
							rel="noreferrer"
						>
							EXPLORE OPPORTUNITIES
						</a>
					</div>
				</div>
			</section>
			<Footer />
		</Fragment>
	);
};

export default Contact;
