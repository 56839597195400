import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './Slideshow.css';
import cover1 from '../assets/images/home/cover1.png';
import cover2 from '../assets/images/home/cover2.png';
import cover3 from '../assets/images/home/cover3.png';
import cover4 from '../assets/images/home/cover4.png';

const Slideshow = () => {
	return (
		<div className="slide-cont">
			<Carousel showStatus={false} stopOnHover useKeyboardArrows autoPlay infiniteLoop interval={5000}>
				<div className="slide-card">
					<img src={cover4} alt="Slide 1" />
					{/* <p className="slideshow-cont">
						<b>One stop solution</b> for all sourcing needs
					</p> */}
				</div>
				<div className="slide-card">
					<img src={cover2} alt="Slide 2" />
					{/* <p className="slideshow-cont">
						<b>One stop solution</b>for all sourcing needs
					</p> */}
				</div>
				<div className="slide-card">
					<img src={cover3} alt="Slide 3" />
					{/* <p className="slideshow-cont">
						<b>One stop solution</b> for all sourcing needs
					</p> */}
				</div>
				<div className="slide-card">
					<img src={cover1} alt="Slide 3" />
					{/* <p className="slideshow-cont">
						<b>One stop solution</b> for all sourcing needs
					</p> */}
				</div>
			</Carousel>
		</div>
	);
};

export default Slideshow;
