import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';

const Team = () => {
	return (
		<Fragment>
			<Navbar />
			This is Our Team Page
		</Fragment>
	);
};

export default Team;
