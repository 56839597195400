import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import Slideshow from '../../components/Slideshow';
import './Home.css';
// import Slider2 from '../../components/Slider2';
import Slider from '../../components/Slider';
import Slider3 from '../../components/Slider3';
import Slider2 from '../../components/Slider2';
import Slider4 from '../../components/Slider4';
import { product_data, value_data, core_data } from '../../components/data';
import Footer from '../../components/Footer';

import trusted1 from '../../assets/images/home/trusted/trusted1.png';
import trusted2 from '../../assets/images/home/trusted/trusted2.png';
import trusted3 from '../../assets/images/home/trusted/trusted3.png';
import trusted4 from '../../assets/images/home/trusted/trusted4.png';
import trusted5 from '../../assets/images/home/trusted/trusted5.png';
import trusted6 from '../../assets/images/home/trusted/trusted6.png';
import trusted7 from '../../assets/images/home/trusted/trusted7.png';
import trusted8 from '../../assets/images/home/trusted/trusted8.png';
const data = [ trusted1, trusted2, trusted3, trusted4, trusted5, trusted6, trusted7, trusted8 ];

const Home = () => {
	return (
		<Fragment>
			<Navbar />
			<Slideshow />
			<section className="section-cont tagline-section">
				<div className="tagline">
					<h1>Your Sourcing Office in India</h1>
					<h2>Reliable & cost-effective</h2>
					<p>
						We offer end-to-end solutions for all your sourcing needs from India. Our best in class services
						in quality, supply chain management and procurement consulting help you<b> save more than you spend</b>.
					</p>
				</div>
			</section>
			<section className="section-cont product-sec">
				<h1 class="section-head">Product Categories</h1>
				<Slider data={product_data} />
			</section>
			{/* <section className="ticker section-cont">
				<TickerMain />
			</section> */}
			<section className="section-cont">
				<h1 class="section-head">Core Services</h1>
				<Slider2 data={core_data} />
			</section>
			<section className="section-cont">
				<h1 class="section-head">Value Added Services</h1>

				<Slider4 data={value_data} />
			</section>
			<section className="section-cont trusted-sec">
				<h1 class="section-head">Trusted By</h1>
				<Slider3 data={data} />
			</section>
			{/* <section className="map section-cont">
				<img src={map} alt="map" />
			</section> */}
			<Footer />
		</Fragment>
	);
};

export default Home;
