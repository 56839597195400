import core1 from '../assets/images/home/core/core_img1.png';
import core2 from '../assets/images/home/core/core_img2.png';
import core3 from '../assets/images/home/core/core_img3.png';
import core4 from '../assets/images/home/core/core_img4.png';
import core5 from '../assets/images/home/core/core_img5.png';
import core6 from '../assets/images/home/core/core_img6.png';
import core7 from '../assets/images/home/core/core_img7.png';
import core8 from '../assets/images/home/core/core_img8.png';
import core9 from '../assets/images/home/core/core_img9.png';
import core10 from '../assets/images/home/core/core_img10.png';

import prod1 from '../assets/images/home/product/prod1.png';
import prod2 from '../assets/images/home/product/prod2.png';
import prod3 from '../assets/images/home/product/prod3.png';
import prod4 from '../assets/images/home/product/prod4.png';

import value1 from '../assets/images/home/value/value1.png';
import value2 from '../assets/images/home/value/value2.png';
import value3 from '../assets/images/home/value/value3.png';
import value4 from '../assets/images/home/value/value4.png';

import textile1 from '../assets/images/products/textile/img1.jpg';
import textile2 from '../assets/images/products/textile/img2.jpg';
import textile3 from '../assets/images/products/textile/img3.jpg';
import textile4 from '../assets/images/products/textile/img4.jpg';
import textile5 from '../assets/images/products/textile/img5.png';
import textile6 from '../assets/images/products/textile/img6.jpg';
import textile7 from '../assets/images/products/textile/img7.jpg';
import textile8 from '../assets/images/products/textile/img8.jpg';
import textile9 from '../assets/images/products/textile/img9.jpg';
import textile10 from '../assets/images/products/textile/img10.jpg';
import textile11 from '../assets/images/products/textile/img11.jpg';
// import textile12 from '../assets/images/products/textile/img12.jpg';
// import textile13 from '../assets/images/products/textile/img13.jpg';
import textile14 from '../assets/images/products/textile/img14.jpg';
import textile15 from '../assets/images/products/textile/img15.jpg';
// import textile16 from '../assets/images/products/textile/img16.jpg';
// import textile17 from '../assets/images/products/textile/img17.jpg';
import textile18 from '../assets/images/products/textile/img18.jpg';
import textile19 from '../assets/images/products/textile/img19.jpg';
import textile20 from '../assets/images/products/textile/img20.jpg';
import textile21 from '../assets/images/products/textile/img21.png';
import textile22 from '../assets/images/products/textile/img22.jpg';
import textile23 from '../assets/images/products/textile/img23.jpg';
import textile24 from '../assets/images/products/textile/img24.jpg';
import textile25 from '../assets/images/products/textile/img25.jpg';
import textile26 from '../assets/images/products/textile/img26.jpg';
import textile27 from '../assets/images/products/textile/img27.jpg';
import textile28 from '../assets/images/products/textile/img28.jpg';
import textile29 from '../assets/images/products/textile/img29.jpg';
import textile30 from '../assets/images/products/textile/img30.jpg';
import textile31 from '../assets/images/products/textile/img31.jpg';
import textile32 from '../assets/images/products/textile/img32.png';

import furniture1 from '../assets/images/products/furniture2/img1.jpg';
import furniture2 from '../assets/images/products/furniture2/img2.jpg';
import furniture3 from '../assets/images/products/furniture2/img3.jpg';
import furniture4 from '../assets/images/products/furniture2/img4.jpg';
import furniture5 from '../assets/images/products/furniture2/img5.jpg';
import furniture6 from '../assets/images/products/furniture2/img6.jpg';
import furniture7 from '../assets/images/products/furniture2/img7.jpg';
import furniture8 from '../assets/images/products/furniture2/img8.jpg';

import decor1 from '../assets/images/products/home_decor2/1.jpg';
import decor2 from '../assets/images/products/home_decor2/2.jpg';
import decor3 from '../assets/images/products/home_decor2/3.jpg';
import decor4 from '../assets/images/products/home_decor2/4.jpg';
import decor5 from '../assets/images/products/home_decor2/5.jpg';
import decor6 from '../assets/images/products/home_decor2/6.jpg';
import decor7 from '../assets/images/products/home_decor2/7.jpg';
import decor8 from '../assets/images/products/home_decor2/8.jpg';
import decor9 from '../assets/images/products/home_decor2/9.jpg';
import decor10 from '../assets/images/products/home_decor2/10.jpg';
import decor11 from '../assets/images/products/home_decor2/11.jpg';
import decor12 from '../assets/images/products/home_decor2/12.jpg';
import decor13 from '../assets/images/products/home_decor2/13.jpg';
import decor14 from '../assets/images/products/home_decor2/14.jpg';
import decor15 from '../assets/images/products/home_decor2/15.jpg';

import improve1 from '../assets/images/products/home_improve2/11.jpg';
import improve2 from '../assets/images/products/home_improve2/22.jpg';
import improve3 from '../assets/images/products/home_improve2/33.jpg';
import improve4 from '../assets/images/products/home_improve2/44.jpg';
import improve5 from '../assets/images/products/home_improve2/55.jpg';
import improve6 from '../assets/images/products/home_improve2/66.jpg';
import improve7 from '../assets/images/products/home_improve2/77.jpg';
import improve8 from '../assets/images/products/home_improve2/88.jpg';
import improve9 from '../assets/images/products/home_improve2/99.jpg';
import improve10 from '../assets/images/products/home_improve2/111.jpg';
import improve11 from '../assets/images/products/home_improve2/222.jpg';
import improve12 from '../assets/images/products/home_improve2/333.jpg';
import improve13 from '../assets/images/products/home_improve2/444.jpg';
import improve14 from '../assets/images/products/home_improve2/555.jpg';
import improve15 from '../assets/images/products/home_improve2/666.jpg';
import improve16 from '../assets/images/products/home_improve2/777.jpg';
import improve17 from '../assets/images/products/home_improve2/888.jpg';
import improve18 from '../assets/images/products/home_improve2/999.jpg';
import improve19 from '../assets/images/products/home_improve2/1111.jpg';
import improve20 from '../assets/images/products/home_improve2/2222.jpg';
import improve21 from '../assets/images/products/home_improve2/3333.jpg';
import improve22 from '../assets/images/products/home_improve2/4444.jpg';
import improve23 from '../assets/images/products/home_improve2/5555.jpg';
import improve24 from '../assets/images/products/home_improve2/6666.jpg';

const product_img = [
	{
		image: textile1
	},
	{
		image: textile2
	},
	{
		image: textile3
	},
	{
		image: textile4
	},
	{
		image: textile5
	},
	{
		image: textile6
	},
	{
		image: textile7
	},
	{
		image: textile8
	},
	{
		image: textile9
	},
	{
		image: textile10
	},
	{
		image: textile11
	},
	{
		image: textile14
	},
	{
		image: textile15
	},
	{
		image: textile18
	},
	{
		image: textile19
	},
	{
		image: textile20
	},
	{
		image: textile21
	},
	{
		image: textile22
	},
	{
		image: textile23
	},
	{
		image: textile24
	},
	{
		image: textile25
	},
	{
		image: textile26
	},
	{
		image: textile27
	},
	{
		image: textile28
	},
	{
		image: textile29
	},
	{
		image: textile30
	},
	{
		image: textile31
	},
	{
		image: textile32
	}
];
const product_img2 = [
	{
		image: furniture1
	},
	{
		image: furniture2
	},
	{
		image: furniture3
	},
	{
		image: furniture4
	},
	{
		image: furniture5
	},
	{
		image: furniture6
	},
	{
		image: furniture7
	},
	{
		image: furniture8
	}
];

const product_img3 = [
	{
		image: decor1
	},
	{
		image: decor2
	},
	{
		image: decor3
	},
	{
		image: decor4
	},
	{
		image: decor5
	},
	{
		image: decor6
	},
	{
		image: decor7
	},
	{
		image: decor8
	},
	{
		image: decor9
	},
	{
		image: decor10
	},
	{
		image: decor11
	},
	{
		image: decor12
	},
	{
		image: decor13
	},
	{
		image: decor14
	},
	{
		image: decor15
	}
];

const product_img4 = [
	{
		image: improve1
	},
	{
		image: improve2
	},
	{
		image: improve3
	},
	{
		image: improve4
	},
	{
		image: improve5
	},
	{
		image: improve6
	},
	{
		image: improve7
	},
	{
		image: improve8
	},
	{
		image: improve9
	},
	{
		image: improve10
	},
	{
		image: improve11
	},
	{
		image: improve12
	},
	{
		image: improve13
	},
	{
		image: improve14
	},
	{
		image: improve15
	},
	{
		image: improve16
	},
	{
		image: improve17
	},
	{
		image: improve18
	},
	{
		image: improve19
	},
	{
		image: improve20
	},
	{
		image: improve21
	},
	{
		image: improve22
	},
	{
		image: improve23
	},
	{
		image: improve24
	}
];

const product_data = [
	{
		name: 'product',
		image: prod1,
		heading: 'TEXTILES',
		para1: `Explore a diverse range of  fabrics and home textiles sourced from top manufacturers in India`,
		para2: `From luxurious silks to durable cottons, we curate quality textiles to meet your import needs`,
		para3: `Discover an array of patterns, colours, and textures for your business success`,
		catalog: ''
	},
	{
		name: 'product',
		image: prod2,
		heading: 'FURNITURE',
		para1: `Browse through a curated selection of exquisite furniture pieces, from modern to classic designs`,
		para2: `Our sourcing capabilities include durable and stylish furniture crafted with precision.`,
		para3: `A blend of aesthetic appeal and functionality. Find the perfect pieces to elevate your market presence`,
		catalog: ''
	},
	{
		name: 'product',
		image: prod3,
		heading: 'HOME IMPROVEMENT',
		para1: `Discover a vast array of high-quality floor tiles, durable hardware, sleek sanitary ware, and elegant bath fittings 
`,
		para2: `Tailored to your business needs, succeed with products that seamlessly blend functionality, style, and durability`,
		para3: `Choose from a curated range of designs to suit your aesthetic preferences`,
		catalog: ''
	},
	{
		name: 'product',
		image: prod4,
		heading: 'HOME DECOR & UTILITY',
		para1: `Discover an exclusive collection of home decoration, accessories, kitchenware, tableware, cookware, and crockery. `,
		para2: ` Functional and decorative products to help your target customers elevate their living spaces and dining experiences - manufactured with care in India`,
		para3: `Tailored to meet your business’ functional, aesthetic and price-sensitive desires`,
		catalog: ''
	}
];

const value_data = [
	{
		name: 'value',
		image: value1,
		heading: 'PHOTOSHOOT STUDIO',
		para1: 'Top-quality Photography Solutions at super-affordable prices',
		para2: `For our clients across the globe, these solutions have helped in building a strong 
e-commerce, brand website and social media presence`,
		para3: `Our state-of-the-art facilities and skilled photographers ensure top-notch imagery that aligns with your brand and goals`,
		catalog: ''
	},
	{
		name: 'value',
		image: value2,
		heading: 'CENTRALIZED PACKAGING SOLUTIONS',
		para1: `Your one-stop service for streamlined and efficient packaging needs`,
		para2: `We optimise packaging processes, reduce costs, and enhance sustainability while delivering high-quality packaging solutions tailored to your specific requirements`,
		para3: `We aim to provide you an end-to-end service by adding value through solutions tailored to your unique packaging needs`,
		catalog: ''
	},
	{
		name: 'value',
		image: value3,
		heading: 'LOW-COST COURIER SERVICES',
		para1: `Our Low-Cost Courier Services offer you a reliable and budget-friendly solution for shipping your samples`,
		para2: `We prioritise affordability without compromising on delivery speed and safety`,
		para3: `Whether it's local or international, our service ensures your packages reach the destination promptly while saving you money, making shipping hassle-free and cost-effective`,
		catalog: ''
	},
	{
		name: 'value',
		image: value4,
		heading: 'LOGISTICS SUPPORT',
		para1: `Our strategic partnerships with freight forwarders and in-house logistics analysts enhance your capability to secure competitive container rates`,
		para2: `By leveraging our unique collaborations, you not only cut expenses but also gain a competitive edge in the global market`,
		para3: `We pride ourselves on providing a comprehensive solution for all your logistics needs, empowering your business to thrive.`,
		catalog: ''
	}
];
const core_data = [
	{
		name: 'core',
		image: core1,
		image2: core2,

		heading: 'Market Research & Competitor Analysis',
		heading2: 'Design Assistance'
	},
	{
		name: 'core',
		image: core4,
		image2: core3,

		heading: 'Production Management',
		heading2: 'Business Process Optimisation'
	},
	{
		name: 'core',
		image: core6,
		image2: core5,

		heading: 'Quality Control & Assurance',
		heading2: 'Supplier Factory Audit'
	},
	{
		name: 'core',
		image: core8,
		image2: core7,

		heading: 'Logistics Support',
		heading2: 'Product Sourcing'
	},
	{
		name: 'core',
		image: core10,
		image2: core9,
		heading: 'After Sales Service',
		heading2: 'Product Compliance & Documentation'
	}
];

export { product_data, value_data, core_data, product_img, product_img2, product_img3, product_img4 };
