import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import Cover from '../../components/Cover';
import vendor_cover from '../../assets/images/vendor/vendor_cover.png';
import './Vendor.css';
// import axios from 'axios';
import Footer from '../../components/Footer';

const Vendor = () => {
	// form states
	// const [ name, setName ] = useState('');
	// const [ phoneNo, setPhoneNo ] = useState('');
	// const [ address, setAddress ] = useState('');
	// const [ email, setEmail ] = useState('');
	// const [ website, setWebsite ] = useState('');
	// const [ products, setProducts ] = useState('');
	// const [ countries, setCountries ] = useState('');
	// const [ turnover, setTurnover ] = useState('');
	// const [ comment, setComment ] = useState('');

	const scrollToTouch = () => {
		const kitchenSection = document.getElementById('touch_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	// const handlePhoneNoChange = (e) => {
	// 	const enteredNumber = e.target.value;
	// 	const phoneRegex = /^\+\d{1,3}-\d{10}$/; // Regex pattern for format +91-1234567890

	// 	if (!phoneRegex.test(enteredNumber)) {
	// 		alert('Please enter the phone number in format, e.g., +91-1234567899');
	// 	} else {
	// 		setPhoneNo(enteredNumber);
	// 	}
	// };
	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	// 	if (!emailRegex.test(email)) {

	// 		alert('Please enter the valid email format');
	// 		return;
	// 	}

	// 	const phoneRegex = /^\+\d{1,3}-\d{10}$/;

	// 	if (!phoneRegex.test(phoneNo)) {
	// 		alert('Please enter the phone number in format, e.g., +91-1234567899');
	// 	} else {
	// 		setPhoneNo(phoneNo);
	// 	}
	// 	const data = {
	// 		name,
	// 		website,
	// 		phoneNo,
	// 		products,
	// 		address,
	// 		countries,
	// 		email,
	// 		turnover,
	// 		comment
	// 	};
	// 	console.log(data);
	// };

	return (
		<Fragment>
			<Navbar />
			<Cover
				coverImageSrc={vendor_cover} // Replace with the actual image source
				title="Be Our Vendor"
				subTitle="Big opportunities await you..."
			/>

			<section className="vendor_sec">
				<div class="form_whole">
					<div className="form-heading">
						<h2>
							<b>Are you an Exporter/Supplier?</b>
						</h2>
						<div className="vendor-form">
							<a href="https://forms.office.com/r/JXj7Sk7yrs" target="_blank" rel="noreferrer">
								Fill this form out!
							</a>
						</div>
						<div class="vendor-form-para">
							<p>Don't worry if you can't see your product on our website.</p>
							<p>
								<b onClick={scrollToTouch}>Connect with us</b> - we have endless capabilities!
							</p>
						</div>
					</div>
					{/* <form autoComplete="off" onSubmit={handleSubmit}>
						<div class="form-group">
							<div class="form-field">
								
								<input
									type="text"
									placeholder="Name of your Company*"
									className="form-control"
									required
									onChange={(e) => setName(e.target.value)}
									value={name}
								/>
							</div>
							<div class="form-field">
								<input
									type="text"
									placeholder="Address (with postal code)*"
									className="form-control"
									required
									onChange={(e) => setAddress(e.target.value)}
									value={address}
								/>
							</div>
							<div class="form-field">
								<input
									type="email"
									className="form-control"
									placeholder="Email-ID*"
									required
									onChange={(e) => setEmail(e.target.value)}
									value={email}
								/>
							</div>
							<div class="form-field">
								<input
									type="text"
									inputMode="numeric"
									placeholder="Phone number (e.g. +91-0000000000)*"
									className="form-control"
									required
									// pattern="^\+\d{1,3}-\d{9,15}$"
									// onChange={handlePhoneNoChange}
									onChange={(e) => setPhoneNo(e.target.value)}
									value={phoneNo}
								/>
							</div>
							<div class="form-field">
								<input
									type="text"
									placeholder="Which products do you produce?*"
									className="form-control"
									required
									onChange={(e) => setProducts(e.target.value)}
									value={products}
								/>
							</div>

							<div class="form-field">
								<input
									type="text"
									className="form-control"
									placeholder="Which countries or exporters are you currently supplying to? "
									onChange={(e) => setCountries(e.target.value)}
									value={countries}
								/>
							</div>

							<div class="form-field">
								<input
									type="text"
									placeholder="Previous 2 immediate years' turnover"
									className="form-control"
									onChange={(e) => setTurnover(e.target.value)}
									value={turnover}
								/>
							</div>
							<div class="form-field">
								<label />
								<input
									type="url"
									className="form-control"
									onChange={(e) => setWebsite(e.target.value)}
									placeholder="Website URL"
									value={website}
								/>
							</div>
							<div class="form-field">
								<textarea
									className="form-control comments"
									placeholder="Additional comments or queries (if any)"
									onChange={(e) => setComment(e.target.value)}
									value={comment}
								/>
							</div>
						</div>
						<div className="submit-cont">
							<button type="submit" className="btn btn-primary">
								Submit
							</button>
						</div>
					</form> */}
				</div>
			</section>
			<section className="vendor_sec getintouch" id="touch_sec">
				<div class="getintouch_whole">
					<div className="getintouch_heading">
						<h1>GET IN TOUCH</h1>
					</div>
					<div className="getintouch_content">
						<table>
							<tr>
								<th>
									<p>Address:</p>
								</th>
								<td>
									<p>
										<a
											href="https://www.google.com/maps?q=127,+Arihant+Nagar,+Punjabi+Bagh+(West),+New+Delhi+-+110026,+India"
											target="_blank"
											rel="noreferrer"
										>
											127, Arihant Nagar, Punjabi Bagh West, New Delhi - 110026, India
										</a>
									</p>
									{/* <p>127, Arihant Nagar, Punjabi Bagh West, New Delhi - 110026, India</p> */}
								</td>
							</tr>
							<tr>
								<th>
									<p>Phone:</p>
								</th>
								<td>
									{/* <p>+91-11-47 02 78 79</p> */}
									<p>
										<a href="tel:+91-11-47 02 78 79">+91-11-47 02 78 79</a>
									</p>
								</td>
							</tr>
							<tr>
								<th>
									<p>Email:</p>
								</th>
								<td>
									<p>
										<a href="mailto:info@sapphiresourcings.com">info@sapphiresourcings.com</a>
									</p>
									{/* <p>info@sapphiresourcings.com</p> */}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</section>

			<Footer />
		</Fragment>
	);
};

export default Vendor;
