import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo_black from '../assets/images/logo_white.png';
import logo_blue from '../assets/images/logo_blue.png';

function Navbar() {
	const [ click, setClick ] = useState(false);
	const [ showNavbar, setShowNavbar ] = useState(false);
	const [ prevScrollPos, setPrevScrollPos ] = useState(0);
	const [ atTop, setAtTop ] = useState(true);
	const [ showDropdown, setShowDropdown ] = useState(false);
	const [ hoverLogo, setHoverLogo ] = useState(false);

	const handleDropdownToggle = () => {
		setShowDropdown(!showDropdown);
	};

	const handleScroll = useCallback(
		() => {
			const currentScrollPos = window.pageYOffset;
			const isScrollingDown = currentScrollPos > prevScrollPos;
			setShowNavbar(!isScrollingDown || currentScrollPos < 10); // Show navbar if scrolling up or at the top
			setPrevScrollPos(currentScrollPos);
			if (currentScrollPos === 0) {
				setShowNavbar(false);
				setAtTop(true);
			} else {
				setAtTop(false);
			}
		},
		[ prevScrollPos ]
	);

	useEffect(
		() => {
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		},
		[ prevScrollPos, handleScroll ]
	);

	const handleClick = () => setClick(!click);

	const handleLogoClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	const handleNavLinkClick = () => {
		if (!atTop) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
		setClick(false); // Close the mobile menu if it's open
	};

	const handleLogoMouseEnter = () => {
		setHoverLogo(true);
	};

	const handleLogoMouseLeave = () => {
		setHoverLogo(false);
	};

	return (
		<Fragment>
			<nav
				className={`navbar ${showNavbar ? 'active-scroll' : ''} ${atTop ? 'atTop' : ''}`}
				onMouseEnter={handleLogoMouseEnter}
				onMouseLeave={handleLogoMouseLeave}
			>
				{atTop ? (
					<div className="nav-logo">
						<NavLink exact to="/" onClick={handleLogoClick}>
							<img
								src={logo_black}
								alt="Logo"
								style={{ opacity: hoverLogo ? 0 : 1, transition: 'opacity 0.3s ease-in-out' }}
							/>
							<img
								src={logo_blue}
								alt="Logo"
								style={{ opacity: hoverLogo ? 1 : 0, transition: 'opacity 0.3s ease-in-out' }}
							/>
						</NavLink>
					</div>
				) : (
					<div className="nav-logo">
						<NavLink exact to="/" onClick={handleLogoClick}>
							<img src={logo_blue} alt="Logo" />
						</NavLink>
					</div>
				)}

				<ul className={click ? 'nav-menu active' : 'nav-menu'}>
					<li
						className="nav-item about-cont"
						onMouseEnter={handleDropdownToggle}
						onMouseLeave={handleDropdownToggle}
					>
						<NavLink
							to="/founder"
							className={`nav-links ${showDropdown ? 'active-dropdown' : ''}`}
							activeClassName="active"
							onClick={handleNavLinkClick}
						>
							Who we are
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							exact
							to="/services"
							activeClassName="active"
							className="nav-links"
							// onClick={handleClick}
							onClick={handleNavLinkClick}
						>
							Why Us?
						</NavLink>
					</li>

					<li className="nav-item">
						<NavLink
							exact
							to="/products"
							activeClassName="active"
							className="nav-links"
							onClick={handleNavLinkClick}
						>
							Products
						</NavLink>
						<NavLink
							exact
							to="/sustainability"
							activeClassName="active"
							className="nav-links"
							// onClick={handleClick}
							onClick={handleNavLinkClick}
						>
							Sustainability
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							exact
							to="/vendor"
							activeClassName="active"
							className="nav-links"
							// onClick={handleClick}
							onClick={handleNavLinkClick}
						>
							Be our Vendor
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							exact
							to="/contact"
							activeClassName="active"
							className="nav-links contact"
							// onClick={handleClick}
							onClick={handleNavLinkClick}
						>
							Let's Connect!
						</NavLink>
					</li>
				</ul>
				<div className={`nav-icon ${atTop ? 'atTopHam' : ''}`} onClick={handleClick}>
					{!click && <MenuIcon className="icon" />}
					{click && <CloseIcon className="icon" />}
				</div>
			</nav>
		</Fragment>
	);
}

export default Navbar;
