import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Slider.css';
// import './Slider2.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import prod1 from '../assets/images/home/product/prod1.png';
import prod2 from '../assets/images/home/product/prod2.png';
import prod3 from '../assets/images/home/product/prod3.png';
import prod4 from '../assets/images/home/product/prod4.png';
import { NavLink } from 'react-router-dom';

const Slider3 = (props) => {
	const { data } = props;
	const [ areImagesLoaded, setAreImagesLoaded ] = useState(true);
	console.log(data);
	useEffect(
		() => {
			const imagePromises = data.map((src) => {
				return new Promise((resolve) => {
					const img = new Image();
					img.src = src;
					img.onload = resolve;
				});
			});

			Promise.all(imagePromises).then(() => {
				setAreImagesLoaded(true);
			});
		},
		[ data ]
	);

	const CustomPrevArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--prev" onClick={onClick}>
				<i className="carousel__btn-arrow carousel__btn-arrow--left" />
			</button>
		);
	};

	const CustomNextArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--next" onClick={onClick}>
				<i className="carousel__btn-arrow carousel__btn-arrow--right" />
			</button>
		);
	};

	// const renderSlides = () =>
	// 	data.map((slide, index) => (
	// 		<div className="slide-container" key={index}>
	// 			<div class="second-cont">
	// 				<div className="slide-img">
	// 					<h2>{slide.heading}</h2>
	// 					<img src={slide.image} alt={`${index + 1}`} onLoad={() => setAreImagesLoaded(true)} />
	// 				</div>
	// 				<div className="content">
	// 					{/* <h2>{slide.heading}</h2> */}

	// 					<p className="content-para">
	// 						{slide.para1}
	// 						<br />
	// 						<br />
	// 						{slide.para2}
	// 						<br />
	// 						<br />
	// 						{slide.para3}
	// 					</p>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	));

	const settings = {
		dots: true,
		customPaging: (i) => <button className="custom-dot" />,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		autoplay: true,
		autoplaySpeed: 3000,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
		responsive: [
			{
				breakpoint: 1230,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 570,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	// const [ atTop, setAtTop ] = useState(true);
	// const [ click, setClick ] = useState(false);
	// const handleNavLinkClick = () => {
	// 	if (!atTop) {
	// 		window.scrollTo({
	// 			top: 0,
	// 			behavior: 'smooth'
	// 		});
	// 	}
	// 	setClick(false); // Close the mobile menu if it's open
	// };
	return (
		<div className="carousel-container">
			{/* {!areImagesLoaded && <h1>Loading Images</h1>}
			{areImagesLoaded && <Slider {...settings}>{renderSlides()}</Slider>} */}
			{!areImagesLoaded && <h1>Loading Images</h1>}
			{areImagesLoaded && (
				<Slider {...settings}>
					<div className="slide-container">
						<NavLink to="/products?scrollTo=textile_sec" className="second-cont">
							<div className="slide-img">
								<h2>TEXTILES</h2>
								<img src={prod1} onLoad={() => setAreImagesLoaded(true)} alt="1" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Explore a <b>diverse range</b> of <b>fabrics</b> and home textiles sourced from top
									manufacturers in India
									<br />
									<br />
									From luxurious silks to durable cottons, we curate quality textiles to
									<b> meet your import needs</b>
									<br />
									<br />
									Discover an array of patterns, colours, and textures for your business success
								</p>
							</div>
						</NavLink>
					</div>

					<div className="slide-container">
						<NavLink to="/products?scrollTo=furniture_sec" className="second-cont">
							<div className="slide-img">
								<h2>FURNITURE</h2>
								<img src={prod2} onLoad={() => setAreImagesLoaded(true)} alt="2" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Browse through a curated selection of exquisite furniture pieces, from
									<b> modern to classic designs</b>
									<br />
									<br />
									Our sourcing capabilities include <b>durable and stylish furniture</b> crafted with
									precision.
									<br />
									<br />
									A blend of <b>aesthetic appeal and functionality</b>. Find the perfect pieces to
									elevate your market presence
								</p>
							</div>
						</NavLink>
					</div>
					<div className="slide-container">
						<NavLink to="/products?scrollTo=improvement_sec" className="second-cont">
							<div className="slide-img">
								<h2>HOME IMPROVEMENT</h2>
								<img src={prod3} onLoad={() => setAreImagesLoaded(true)} alt="3" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Discover a vast array of high-quality
									<b> floor tiles, durable hardware, sleek sanitary ware</b>, and
									<b> elegant bath fittings</b>
									<br />
									<br />
									Tailored to your business needs, succeed with products that seamlessly blend
									<b> functionality, style</b>, and <b>durability</b>
									<br />
									<br />
									Choose from a curated range of designs to suit your aesthetic preferences
								</p>
							</div>
						</NavLink>
					</div>
					<div className="slide-container">
						<NavLink to="/products?scrollTo=decor_sec" className="second-cont">
							<div className="slide-img">
								<h2>HOME DECOR & UTILITY</h2>
								<img src={prod4} onLoad={() => setAreImagesLoaded(true)} alt="4" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Discover an exclusive collection of
									<b> home decoration, accessories, kitchenware, tableware, cookware</b>, and
									<b> crockery</b>.
									<br />
									<br />
									<b>Functional</b> and <b>decorative products</b> to help your target customers
									elevate their living spaces and dining experiences - manufactured with care in India
									<br />
									<br />
									Tailored to meet your business’ functional, aesthetic and price-sensitive desires
								</p>
							</div>
						</NavLink>
					</div>
				</Slider>
			)}
		</div>
	);
};

export default Slider3;
