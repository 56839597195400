import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import why_cover from '../../assets/images/why/why_cover.png';
import './Services.css';
import Cover from '../../components/Cover';
import Footer from '../../components/Footer';
import logo1 from '../../assets/images/why/1.png';
import logo2 from '../../assets/images/why/2.png';
import logo3 from '../../assets/images/why/3.png';
import logo4 from '../../assets/images/why/4.png';
import logo5 from '../../assets/images/why/5.png';
import logo6 from '../../assets/images/why/6.png';

const Services = () => {
	return (
		<Fragment>
			<Navbar />
			<Cover
				coverImageSrc={why_cover} // Replace with the actual image source
				title="Why Us?"
				subTitle="We’ll take all your worries away!"
			/>
			<section className="why-sec">
				<div className="why-intro-whole">
					<h1>The Sapphire Sourcings Advantage</h1>
					<p>
						Discover unparalleled sourcing expertise tailored to your needs. Driven by sustainability and
						integrity.
					</p>
				</div>
			</section>
			<section className="why-sec sec-back">
				<div className="why-grid-whole">
					<div className="why-card card-left">
						<div className="why-card-cont">
							<h1>Quality, as Promised</h1>
							<p>Delivering promised quality through regular and sophisticated quality checks</p>
						</div>
						<img src={logo1} alt="" />
					</div>
					<div className="why-card card-right">
						<div className="why-card-cont content-card-right">
							<h1>Finding the Perfect Fit</h1>
							<p className="para-right">
								Unlocking perfect product-fits from a deep-rooted network across the Indian
								subcontinent.
							</p>
						</div>
						<img src={logo2} alt="" />
					</div>
					<div className="why-card card-left">
						<div className="why-card-cont">
							<h1>Savings, through experience</h1>
							<p>
								Years of expertise save costs, consulting your business at every step of the supply
								chain
							</p>
						</div>
						<img src={logo3} alt="" />
					</div>
					<div className="why-card card-right">
						<div className="why-card-cont content-card-right">
							<h1>Your Eyes & Ears in India</h1>
							<p className="para-right">
								We stand for our buyers' interests, ensuring transparency and trustworthy representation
							</p>
						</div>
						<img src={logo4} alt="" />
					</div>
					<div className="why-card card-left">
						<div className="why-card-cont">
							<h1>Regulatory Compliances & Safety</h1>
							<p>
								Suppliers compliant with industry-best regulations for different geographies around the
								world. Top tier client confidentiality
							</p>
						</div>
						<img src={logo5} alt="" />
					</div>
					<div className="why-card card-right">
						<div className="why-card-cont content-card-right">
							<h1>Tailored to your needs</h1>
							<p className="para-right">
								Seamlessly blending in with unique needs of your business. We go the extra mile, to make
								you smile.
							</p>
						</div>
						<img src={logo6} alt="" />
					</div>
				</div>
			</section>

			<Footer />
		</Fragment>
	);
};

export default Services;
