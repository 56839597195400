import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Slider.css';
// import './Slider2.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import value1 from '../assets/images/home/value/value1.png';
import value2 from '../assets/images/home/value/value2.png';
import value3 from '../assets/images/home/value/value3.png';
import value4 from '../assets/images/home/value/value4.png';

const Slider3 = (props) => {
	const { data } = props;
	const [ areImagesLoaded, setAreImagesLoaded ] = useState(true);
	console.log(data);
	useEffect(
		() => {
			const imagePromises = data.map((src) => {
				return new Promise((resolve) => {
					const img = new Image();
					img.src = src;
					img.onload = resolve;
				});
			});

			Promise.all(imagePromises).then(() => {
				setAreImagesLoaded(true);
			});
		},
		[ data ]
	);

	const CustomPrevArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--prev" onClick={onClick}>
				<i className="carousel__btn-arrow carousel__btn-arrow--left" />
			</button>
		);
	};

	const CustomNextArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--next" onClick={onClick}>
				<i className="carousel__btn-arrow carousel__btn-arrow--right" />
			</button>
		);
	};

	// const renderSlides = () =>
	// 	data.map((slide, index) => (
	// 		<div className="slide-container" key={index}>
	// 			<div class="second-cont">
	// 				<div className="slide-img">
	// 					<h2>{slide.heading}</h2>
	// 					<img src={slide.image} alt={`${index + 1}`} onLoad={() => setAreImagesLoaded(true)} />
	// 				</div>
	// 				<div className="content">
	// 					{/* <h2>{slide.heading}</h2> */}

	// 					<p className="content-para">
	// 						{slide.para1}
	// 						<br />
	// 						<br />
	// 						{slide.para2}
	// 						<br />
	// 						<br />
	// 						{slide.para3}
	// 					</p>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	));

	const settings = {
		dots: true,
		customPaging: (i) => <button className="custom-dot" />,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		autoplay: true,
		autoplaySpeed: 3000,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
		responsive: [
			{
				breakpoint: 1230,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 570,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<div className="carousel-container">
			{/* {!areImagesLoaded && <h1>Loading Images</h1>}
			{areImagesLoaded && <Slider {...settings}>{renderSlides()}</Slider>} */}
			{!areImagesLoaded && <h1>Loading Images</h1>}
			{areImagesLoaded && (
				<Slider {...settings}>
					<div className="slide-container">
						<div class="second-cont">
							<div className="slide-img">
								<h2>PHOTOSHOOT STUDIO</h2>
								<img src={value1} onLoad={() => setAreImagesLoaded(true)} alt="1" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Top-quality <b>Photography Solutions</b> at super-affordable prices
									<br />
									<br />
									For our clients across the globe, these solutions have helped in building a strong
									<b> e-commerce, brand website</b> and <b>social media</b> presence
									<br />
									<br />
									Our state-of-the-art facilities and skilled photographers ensure top-notch imagery
									that aligns with your brand and goals
								</p>
							</div>
						</div>
					</div>
					<div className="slide-container">
						<div class="second-cont">
							<div className="slide-img">
								<h2>CENTRALIZED PACKAGING SOLUTIONS</h2>
								<img src={value2} onLoad={() => setAreImagesLoaded(true)} alt="2" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Your one-stop service for streamlined and efficient <b>packaging needs</b>
									<br />
									<br />
									We optimise <b>packaging processes, reduce costs</b>, and
									<b> enhance sustainability</b> while delivering high-quality packaging solutions
									tailored to your specific requirements
									<br />
									<br />
									We aim to provide you an <b>end-to-end service</b> by adding value through solutions
									tailored to your unique packaging needs
								</p>
							</div>
						</div>
					</div>
					<div className="slide-container">
						<div class="second-cont">
							<div className="slide-img">
								<h2>LOW-COST COURIER SERVICES</h2>
								<img src={value3} onLoad={() => setAreImagesLoaded(true)} alt="3" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Our Low-Cost Courier Services offer you a <b>reliable</b> and
									<b> budget-friendly</b> solution for shipping your samples
									<br />
									<br />
									We prioritise <b>affordability</b> without compromising on delivery speed and safety
									<br />
									<br />
									Whether it's local or international, our service ensures your packages reach the
									destination promptly while <b>saving you money, making shipping hassle-free</b> and
									<b> cost-effective</b>
								</p>
							</div>
						</div>
					</div>
					<div className="slide-container">
						<div class="second-cont">
							<div className="slide-img">
								<h2>LOGISTICS SUPPORT</h2>
								<img src={value4} onLoad={() => setAreImagesLoaded(true)} alt="4" />
							</div>
							<div className="content">
								{/* <h2>{slide.heading}</h2> */}

								<p className="content-para">
									Our strategic partnerships with freight forwarders and in-house logistics analysts
									enhance your capability to secure competitive container rates
									<br />
									<br />
									By leveraging our unique collaborations, you not only cut expenses but also gain a
									competitive edge in the global market
									<br />
									<br />
									We pride ourselves on providing a comprehensive solution for all your logistics
									needs, empowering your business to thrive.
								</p>
							</div>
						</div>
					</div>
				</Slider>
			)}
		</div>
	);
};

export default Slider3;
