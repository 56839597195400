import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/HomePage/Home';
import Founder from './pages/AboutPage/Founder';
import Company from './pages/AboutPage/Company';
import Sustainability from './pages/SustainabilityPage/Sustainability';
import Services from './pages/ServicesPage/Services';
import Products from './pages/ProductsPage/Products';
import Vendor from './pages/VendorPage/Vendor';
import Contact from './pages/ContactPage/Contact';
import Team from './pages/TeamPage/Team';

function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />} exact />
			{/* <Route path="/about" element={<About />} /> */}
			<Route path="/founder" element={<Founder />} />
			<Route path="/company" element={<Company />} />
			<Route path="/team" element={<Team />} />
			<Route path="/products" element={<Products />} />
			<Route path="/sustainability" element={<Sustainability />} />
			<Route path="/services" element={<Services />} />
			<Route path="/vendor" element={<Vendor />} />
			<Route path="/contact" element={<Contact />} />
		</Routes>
	);
}

export default App;
