import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import sust_cover from '../../assets/images/sustainability/sust_cover.png';
import Cover from '../../components/Cover';
import './Sustainability.css';
import Footer from '../../components/Footer';
import ethical from '../../assets/images/sustainability/ethical.png';
import leaf from '../../assets/images/sustainability/leaf.png';

import cert1 from '../../assets/images/sustainability/cert1.png';
import cert2 from '../../assets/images/sustainability/cert2.png';
import cert3 from '../../assets/images/sustainability/cert3.png';
import cert4 from '../../assets/images/sustainability/cert4.png';
import cert5 from '../../assets/images/sustainability/cert5.png';

import list1 from '../../assets/images/sustainability/list-img1.png';
import list2 from '../../assets/images/sustainability/list-img2.png';
import list3 from '../../assets/images/sustainability/list-img3.png';

import sust_sapphire from '../../assets/images/sustainability/sust_sapphire.png';
import sust_living from '../../assets/images/sustainability/living.png';
import sust_cotton from '../../assets/images/sustainability/cotton.png';

import Slider3 from '../../components/Slider3';

const data = [ cert1, cert2, cert3, cert4, cert5 ];
// import cert1 from "../../assets/images/sustainability/cert1.png";

const Sustainability = () => {
	return (
		<Fragment>
			<Navbar />
			<Cover
				coverImageSrc={sust_cover} // Replace with the actual image source
				title="Sustainability"
				subTitle="A vast and diverse range of products."
			/>
			<section className="sust-sec">
				<div className="ethical-whole">
					<img src={ethical} alt="" />
					<div className="ethical-content">
						<h1>Ethical Sourcing of Sustainable Products</h1>
						<p>More than 50% of our production is made with sustainable materials</p>
						<img src={leaf} alt="" />
					</div>
				</div>
			</section>
			<div class="hr-sust">
				<hr />
			</div>
			<section className="sust-sec">
				<div class="cert-whole">
					<h1>Certifications</h1>
					<Slider3 data={data} />
				</div>
			</section>
			<section className="sust-sec sec-back">
				<p className="offer-line">
					We offer a wide range of sustainable fabric options across all product divisions we source
				</p>
			</section>
			<section className="sust-sec">
				<div className="sust-list-whole">
					<ul>
						<li>FSC / Ecovera / Eco Liva Viscose</li>
						<li>BCI & Organic Cotton</li>
						<li>Recycled Cotton</li>
						<li>Tencel & RE-FIBRA Tencel</li>
						<li>Recycled Poly</li>
						<li>Hemp</li>
						<li>Bamboo</li>
						<li>Sustainable Denim</li>
						<li>Special Finishes – Health Care & Protective Finishes</li>
						<li>FSC wood</li>
						<li>
							<b>Sustainable packaging</b> solutions such as corn starch packaging among others
						</li>
					</ul>
					<div className="sust-list-images">
						<img src={list1} alt="" />
						<div className="list-img-div">
							<img src={list2} alt="" />
							<img src={list3} alt="" />
						</div>
					</div>
				</div>
			</section>
			<section className="sust-sec sec-back">
				<div class="sust-sapphire-whole">
					<img src={sust_sapphire} alt="" />
					<div className="sust-sapphire-content">
						<h1>Sustainability at Sapphire</h1>
						<p>
							At Sapphire Sourcings, we really care about our planet. We work super hard to find products
							in a way that doesn't hurt the environment.
							<br />
							<br />From where we get things to how we deliver them, we make sure it's all done in a
							really good and green way.
						</p>
					</div>
				</div>
			</section>

			<section className="sust-sec sec-back">
				<div class="sust-sapphire-whole sust-reverse">
					<img src={sust_living} alt="" />
					<div className="sust-sapphire-content content-reverse">
						<h1>Sustainable Living</h1>
						<div class="content-para">
							<p>Sustainable living offers at Sapphire include:</p>
							<ul>
								<li>Tencel & RE-FIBRA Tencel</li>
								<li>Sustainable Cotton – Organic, BCI, Re-cycled</li>
								<li>Recycled Polyester</li>
								<li>Sustainable Viscose – EcoVero, LivaEco, FSC certified, Dope Dyed</li>
								<li>Hemp</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="sust-sec sec-back">
				<div class="sust-sapphire-whole">
					<img src={sust_cotton} alt="" />
					<div className="sust-sapphire-content">
						<h1>Sustainable Cotton</h1>
						<p>
							We offer sustainable cotton in all divisions and work with GOT certified mills with
							certification available.
							<br />
							<br />BCI / Organic is our preferred route with lots of options available.
						</p>
					</div>
				</div>
			</section>

			<Footer />
		</Fragment>
	);
};

export default Sustainability;
