import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Slider3.css';
// import './Slider2.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slider3 = (props) => {
	const { data } = props;
	const [ areImagesLoaded, setAreImagesLoaded ] = useState(false);

	useEffect(
		() => {
			const imagePromises = data.map((src) => {
				return new Promise((resolve) => {
					const img = new Image();
					img.src = src;
					img.onload = resolve;
				});
			});

			Promise.all(imagePromises).then(() => {
				setAreImagesLoaded(true);
			});
		},
		[ data ]
	);

	const CustomPrevArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--prev" onClick={onClick}>
				<i className="carousel__btn-arrow trusted-arrow carousel__btn-arrow--left" />
			</button>
		);
	};

	const CustomNextArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--next" onClick={onClick}>
				<i className="carousel__btn-arrow trusted-arrow carousel__btn-arrow--right" />
			</button>
		);
	};

	const renderSlides = () =>
		data.map((image, index) => (
			<div className="trusted-slide-img" key={index}>
				<img src={image} alt={`Slide ${index + 1}`} />
			</div>
		));

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1000,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			}
		],
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />
	};

	return (
		<div className="carousel-container">
			{!areImagesLoaded && <h1>Loading Images</h1>}
			{areImagesLoaded && <Slider {...settings}>{renderSlides()}</Slider>}
		</div>
	);
};

export default Slider3;
