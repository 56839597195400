// import React, { Fragment } from 'react';
// import Navbar from '../../components/Navbar';
// import cover from '../../assets/images/company/company_cover.png';

// import mission from '../../assets/images/company/mission.png';
import './Company.css';
// import Footer from '../../components/Footer';
// import Cover from '../../components/Cover';
// import Founder from "../AboutPage/Founder";
import val1 from '../../assets/images/founder/val1.png';
import val2 from '../../assets/images/founder/val2.png';
import val3 from '../../assets/images/founder/val3.png';
import val4 from '../../assets/images/founder/val4.png';
import val5 from '../../assets/images/founder/val5.png';
import val6 from '../../assets/images/founder/val6.png';

const Company = () => {
	// const scrollToText = () => {
	// 	const kitchenSection = document.getElementById('textile_sec');
	// 	kitchenSection.scrollIntoView({ behavior: 'smooth' });
	// };
	return (
		<div>
			<section className="section-company mission-vision">
				<div class="mv-whole">
					<div className="mv-card">
						{/* <img src={mission} alt="" /> */}
						<div class="mv-heading mission-img">
							<div class="black-background" />
							<h1>MISSION</h1>
						</div>
						<div className="mv-content">
							<p className="para">
								To offer customised procurement and supply-chain solutions that add value for our
								clients
							</p>
							<p className="para2">
								Delivering cost-effective and timely services by following ethical business practices
							</p>
							<hr />
							<h2>Our Priorities</h2>
							<div className="priority">
								<p>Trust</p>
								<p>Transparency</p>
								<p>Communication</p>
							</div>
						</div>
					</div>
					<div className="mv-card">
						{/* <img src={mission} alt="" /> */}
						<div class="mv-heading vision-img">
							<h1>VISION</h1>
							<div class="black-background" />
						</div>
						<div className="mv-content">
							<p className="para">
								We aim to be the top procurement and supply chain management partner for global
								business.
							</p>
							<p className="para2">
								To provide the best sourcing solutions and help clients achieve their goals.
							</p>
							<hr />
							<h2>Our Priorities</h2>
							<div className="priority">
								<p>Quality</p>
								<p>Reliability</p>
								<p>Sustainability</p>
							</div>
						</div>
					</div>
				</div>
				<div className="values">
					<div className="mv-heading values-img">
						<h1>VALUES</h1>
						<div class="black-background" />
					</div>
					<div className="value-after">
						<h1>Residing at the heart of Sapphire Sourcings </h1>
						<p>
							The <b>6 values</b> collectively form the acronym <b>“CIRCLE”</b> - a conscious and
							consistent effort to create an ethical and sustainable working world around us
						</p>
					</div>
					<div class="values-content-whole">
						<div className="values-card val1">
							<img src={val1} alt="" />
						</div>
						<div className="values-card val2">
							<img src={val2} alt="" />
						</div>
						<div className="values-card val1">
							<img src={val3} alt="" />
						</div>
						<div className="values-card val1">
							<img src={val4} alt="" />
						</div>
						<div className="values-card val1">
							<img src={val5} alt="" />
						</div>
						<div className="values-card val1">
							<img src={val6} alt="" />
						</div>
					</div>
				</div>
				{/* <div className="value-after">
					<h1>Residing at the heart of Sapphire Sourcings </h1>
					<p>
						The <b>6 values</b> collectively form the acronym <b>“CIRCLE”</b> - a conscious and consistent
						effort to create an ethical and sustainable working world around us
					</p>
				</div> */}
			</section>

			{/* <Footer /> */}
		</div>
	);
};

export default Company;
