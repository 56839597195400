import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';
import logo from '../assets/images/logo_foot.jpg';
import linkedin from '../assets/images/linkedin.png';

const Footer = () => {
	const linkedinUrl = 'https://www.linkedin.com/company/sapphire-sourcings/';
	const handleLogoClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	return (
		<footer className="foot-cont">
			<div className="whole-container">
				<div className="foot-logo">
					<NavLink exact to="/" onClick={handleLogoClick}>
						<img src={logo} alt="" />
					</NavLink>
				</div>
				<div className="foot-content">
					<div className="box foot-link">
						<ul>
							<li>
								<NavLink exact to="/" className="foot-nav-links">
									Home
								</NavLink>
							</li>
							<li>
								<NavLink exact to="/founder" className="foot-nav-links">
									Who we are
								</NavLink>
							</li>
							<li>
								<NavLink exact to="/services" className="foot-nav-links">
									Why Us?
								</NavLink>
							</li>
							<li>
								<NavLink exact to="/products" className="foot-nav-links">
									Products
								</NavLink>
							</li>
							<li>
								<NavLink exact to="/sustainability" className="foot-nav-links">
									Sustainability
								</NavLink>
							</li>
							<li>
								<NavLink exact to="/vendor" className="foot-nav-links">
									Be our Vendor
								</NavLink>
							</li>
							<li>
								<NavLink exact to="/contact" className="foot-nav-links">
									Let's Connect!
								</NavLink>
							</li>
						</ul>
					</div>
					<div class="content-linkedin">
						<div class="content-box">
							<div className="box foot-head-office">
								<p>Head Office - New Delhi (India)</p>
								<p>
									<b>SAPPHIRE SOURCINGS</b>
									127, Arihant Nagar, Punjabi Bagh West, New Delhi - 110026, INDIA
								</p>
								<p>info@sapphiresourcings.com</p>
							</div>
							<div className="copyright">
								<p>Copyright ©2023 All rights reserved by Sapphire Sourcings</p>
							</div>
						</div>
						<a className="linkedin-link" href={linkedinUrl} target="_blank" rel="noopener noreferrer">
							<img src={linkedin} alt="LinkedIn" />
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
