import React, { Fragment, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Cover from '../../components/Cover';
import founder_cover from '../../assets/images/founder/founder_cover.jpg';
import intro from '../../assets/images/founder/intro.jpg';
import intro2 from '../../assets/images/founder/intro2.jpg';
import team1 from '../../assets/images/founder/team/team1.png';
import team2 from '../../assets/images/founder/team/team2.png';
import team3 from '../../assets/images/founder/team/team3.png';
import './Founder.css';
import Footer from '../../components/Footer';
// import Team from '../../components/Team';
// import CoverDown from '../../components/CoverDown';
import Company from './Company';
import ProductCarousel from '../../components/ProductCarousel';

const About = () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const scrollTo = urlParams.get('scrollTo');

		if (scrollTo === 'company') {
			const companySection = document.getElementById('company');
			if (companySection) {
				window.scrollTo({
					top: companySection.offsetTop,
					behavior: 'smooth'
				});
			}
		}
	}, []);
	//TEAM CONTENT
	const images = [
		{
			image: team1,
			alt: 'Team Member 1'
		},
		{
			image: team2,
			alt: 'Team Member 2'
		},
		{
			image: team3,
			alt: 'Team Member 3'
		}
	];

	return (
		<Fragment>
			<Navbar />
			<Cover coverImageSrc={founder_cover} title="Who we are" subTitle="We want you to know all about us!" />
			<section className="section-main section-top section-about">
				<div className="intro">
					<div class="intro-img">
						<img className="image-inside1" src={intro} alt="" />
					</div>
					<div className="intro-content">
						<h2>“Behind every product we source, there's a story waiting to be told”</h2>
						{/* <br /> */}
						<p className="f-intro-para">
							I started Sapphire Sourcings with the goal of helping companies streamline their procurement
							processes and reduce costs while maintaining the highest levels of quality, reliability and
							sustainability.
							<br />
							<br />
							Over the years, our team at Sapphire has been committed to integrity, sustainability, and
							excellence in global trade.
							<br />
							<br />
							I humbly invite you to join us in this journey, and help us <b>make your business grow!</b>
						</p>
					</div>
				</div>
			</section>

			<section className="section-main section-est section-about">
				<div className="intro intro-est">
					<div className="intro-content">
						<div class="content-est">
							<p className="f-intro-para">
								Established in 2008, headquartered in Delhi, India - <b>Sapphire Sourcings</b> is an
								experienced procurement and supply chain management company that specializes in
								providing customized sourcing solutions to businesses of all sizes.
								<br />
								<br />
								We hold trust of our global clientele with ethical business practices and help optimize
								their procurement processes to drive growth.
								<br />
								<br />
								With our expertise, global reach, and commitment to sustainability, our company is
								well-positioned to help businesses succeed in today's competitive international markets.
							</p>
						</div>
					</div>
					<div class="intro-img img-est">
						<img className="image-inside1" src={intro2} alt="" />
					</div>
				</div>
			</section>
			<section className="section-company team-sec">
				{/* <Team heading={heading} paragraph={paragraph} images={images} /> */}
				<div className="team-sec">
					<h1>Our Team</h1>
					<p>
						Our team embodies market expertise, multicultural sensitivity, and strong negotiation skills.
						With <b>attention to detail</b> and a <b>customer-centric approach</b>, we ensure seamless
						sourcing and collaborative operations.
					</p>
					{/* <div className="team-img">
						{images.map((image, index) => <img key={index} src={image.src} alt={image.alt} />)}
					</div> */}
					<ProductCarousel images={images} />
				</div>
			</section>
			<div id="company">
				<Company />
			</div>
			<Footer />
		</Fragment>
	);
};

export default About;
