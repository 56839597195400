import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Slider2.css';
import './Slider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slider2 = (props) => {
	const { data } = props;
	const [ areImagesLoaded, setAreImagesLoaded ] = useState(true);
	console.log(data);
	useEffect(
		() => {
			const imagePromises = data.map((src) => {
				return new Promise((resolve) => {
					const img = new Image();
					img.src = src;
					img.onload = resolve;
				});
			});

			Promise.all(imagePromises).then(() => {
				setAreImagesLoaded(true);
			});
		},
		[ data ]
	);

	const CustomPrevArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--prev" onClick={onClick}>
				<i className="carousel__btn-arrow carousel__btn-arrow--left" />
			</button>
		);
	};

	const CustomNextArrow = (props) => {
		const { onClick } = props;
		return (
			<button className="carousel__btn carousel__btn--next" onClick={onClick}>
				<i className="carousel__btn-arrow carousel__btn-arrow--right" />
			</button>
		);
	};

	const renderSlides = () =>
		data.map((slide, index) => (
			<div className="slide-container-2" key={index}>
				<div class={`slide-img-whole ${index % 2 === 1 ? 'reverse-col' : ''}`}>
					<div className="slide-img-2 first-img">
						<div class="both-in first-in">
							<div class="slide-image">
								<img src={slide.image} alt={`${index + 1}`} onLoad={() => setAreImagesLoaded(true)} />
							</div>
							{/* <h2 style={{ color: index % 2 === 0 ? '#182268' : '#c1272d' }}>{slide.heading}</h2> */}
							<h2 className="blue-heading">{slide.heading}</h2>
						</div>
					</div>

					<div className="slide-img-2 second-img">
						<div class="both-in second-in">
							<div class="slide-image">
								<img src={slide.image2} alt={`${index + 1}`} onLoad={() => setAreImagesLoaded(true)} />
							</div>
							<h2 className="grey-heading">{slide.heading2}</h2>
						</div>
					</div>
				</div>
			</div>
		));
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 850,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 570,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		],
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />
	};

	return (
		<div className="carousel-container">
			{!areImagesLoaded && <h1>Loading Images</h1>}
			{areImagesLoaded && <Slider {...settings}>{renderSlides()}</Slider>}
			{/* {areImagesLoaded && (
				<Slider {...settings}>
					<div className="slide-container-2">
						<div class="slide-img-whole first-whole">
							<div className="slide-img-2 first-img">
								<div class="slide-image">
									<img src={c_image1} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								{/* <h2 style={{ color: index % 2 === 0 ? '#182268' : '#c1272d' }}>{slide.heading}</h2> 
								<h2 className="blue-heading">Market Research & Competitor analysis</h2>
							</div>
							<hr />
							<div className="slide-img-2 second-img">
								<div class="slide-image">
									<img src={c_image2} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								<h2 className="grey-heading">Design Assistance</h2>
							</div>
						</div>
						<div class="slide-img-whole second-whole">
							<div className="slide-img-2 first-img">
								<div class="slide-image">
									<img src={c_image3} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								<h2 className="grey-heading">Business Process Optimisation</h2>
							</div>
							<hr />
							<div className="slide-img-2 second-img">
								<div class="slide-image">
									<img src={c_image4} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								<h2 className="blue-heading">Production Management</h2>
							</div>
						</div>
					</div>
					<div className="slide-container-2">
						<div class="slide-img-whole first-whole">
							<div className="slide-img-2 first-img">
								<div class="slide-image">
									<img src={c_image1} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								{/* <h2 style={{ color: index % 2 === 0 ? '#182268' : '#c1272d' }}>{slide.heading}</h2> 
								<h2 className="blue-heading">Market Research & Competitor analysis</h2>
							</div>
							<hr />
							<div className="slide-img-2 second-img">
								<div class="slide-image">
									<img src={c_image2} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								<h2 className="grey-heading">Design Assistance</h2>
							</div>
						</div>
						<div class="slide-img-whole second-whole">
							<div className="slide-img-2 first-img">
								<div class="slide-image">
									<img src={c_image3} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								<h2 className="grey-heading">Business Process Optimisation</h2>
							</div>
							<hr />
							<div className="slide-img-2 second-img">
								<div class="slide-image">
									<img src={c_image4} onLoad={() => setAreImagesLoaded(true)} />
								</div>
								<h2 className="blue-heading">Production Management</h2>
							</div>
						</div>
					</div>
				</Slider>
			)} */}
		</div>
	);
};

export default Slider2;
